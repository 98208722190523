@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 2px;
  }
    
  *::-webkit-scrollbar-thumb {
    background-color: rgb(96 165 250) ;
    border-radius: 14px;
    border: 3px solid var(--primary);
  }