.terminal {
  display: flex; /* Make the terminal a flex container */
  align-items: center; /* Align items vertically in the center */
}

.text-container {
  flex-grow: 0; /* Do not allow the container to grow initially */
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent wrapping */
  animation: revealText 4s steps(40, end) forwards;
}

@keyframes revealText {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typed-text {
  display: inline-block;
  white-space: nowrap; /* Prevent wrapping */
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: blink 1s infinite alternate;
  overflow: hidden;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}
