.wysiwyg{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    max-height: 60rem ;
    flex-direction: column;
}

.wysiwyg h1 {
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 600;
}

.wysiwyg h2 {
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
}

.wysiwyg h3 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
}

.wysiwyg p {
    font-size: 1rem;
    font-style: normal;
    line-height: 160%;
}


.wysiwyg pre{
    background-color: #fff;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 160%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    width: fit-content;
}

.wysiwyg pre>code{
    background-color: transparent;
    padding: none!important;
}

.wysiwyg code{
    background-color: #fff;
    color:rgb(23 37 84 ) ;
    border-radius: 0.25rem;
    font-size: 1rem;
}






.wysiwyg li{    
    font-size: 1rem;
    font-style: normal;
    line-height: 160%;
}

.wysiwyg ul li::before{
    content:"• ";
    margin-top: 1rem;
    margin-bottom: 1rem;
    

}

.wysiwyg ol{
    list-style-type: decimal;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
}

.wysiwyg blockquote{
    font-size: 1rem;
    font-style: normal;
    border-left: 0.25rem solid #FFF;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    
}